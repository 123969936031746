:root{
  --main-color: #EDF2F7;
  --border-color: #CBD5E0;
  --main-color-strong: rgb(94,153,223);
  --green: rgba(0, 105, 0, 0.644);
  --red: rgba(204, 0, 0, 0.705);
  --border-color-input: #3182ce;
}

/* width */
::-webkit-scrollbar {
  width: 0px;
}

*{
  font-family: 'Montserrat', sans-serif !important;
}

html,body{
  margin: 0;
  overflow-x: hidden !important;
  background: #f4f4f4 !important;
}

p,span,h1,h2,h3,h4,h5,div{
  color: #535353 !important;
}

.chakra-input:focus-visible{
  border-color: var(--border-color-input) !important;
}

.chakra-select{
  background: var(--chakra-colors-gray-100) !important;
}

.chakra-select:focus-visible{
  border-color: var(--border-color-input) !important;
}

.pagination{
  display: flex;
  gap: 10px;
}

.causal-active{
  color: white !important;
}

.badge-license{
  width: 150px;
  color: white !important;
  text-align: center;
}

.badge-license.small{
  width: 120px;
}

tr.expired{
  background-color: rgba(255, 0, 0, 0.192) !important;
}

tr.in-expiration{
  background-color: rgba(253, 215, 0, 0.459) !important;
}

tr.expired > td{
  background: transparent !important;
}

tr.in-expiration > td{
  background: transparent !important;
}

.history-card{
  border-radius: 10px;
  border: 1px solid var(--border-color);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
  gap: 10px;
}

.cancel-search{
  cursor: pointer;
  color: var(--red) !important;
  margin-top: 10px;
}

.button-new:hover{
  background-color: var(--green) !important;
}

.stat-card{
  border: 1px solid var(--border-color);
  width: 350px;
  height: 200px;
  border-radius: 15px;
  text-align: center;
}

.button-pagination-active{
  background-color: var(--main-color-strong) !important;
  color: white !important;
}

.button-delete:hover{
  background-color: var(--red) !important;
}

.chakra-divider{
  border-color: var(--border-color) !important;
}

.chakra-checkbox__control > div{
  color: white !important;
}

.action-table{
  display: flex;
  gap: 15px;
  font-size: 25px !important;
  cursor: pointer;
}

.chakra-alert__icon, .chakra-alert__title, .chakra-alert__desc{
  color: white !important;
}

.chakra-alert > button{
  color: white !important;
}

.sidebar{
  width: 300px;
  height: 100vh;
  /*border-right: 1px solid var(--main-color);*/
  background-color: white !important;
  box-shadow: 10px 0px 100px 0.1px #e3e3e3a3;
}

.sidebar .header{
  height: 100px;
  width: 100%;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.content{
  width: 100%;
  height: 100vh;
}

.row-menu{
  cursor: pointer;
  padding: 10px;
  /*border-bottom: 1px solid var(--main-color);*/
  transition: .2s;
}

.row-menu:hover{
  background-color: var(--main-color);
}

.row-menu .icon{
  width: 30px;
  display: flex;
  justify-content: center;
  font-size: 18px;
}

.row-menu .text{
  width: 70%;
  text-align: right;
  font-size: 18px;
}

.exit-button{
  color: white !important;
}

.row-menu.active{
  background: var(--main-color);
}

.row-menu.active>*{
  font-weight: 600;
}

.masthead{
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.content .body{
  width: 100%;
  height: 100%;
  padding: 30px;
  font-size: 20px;
  overflow: auto;
}

.chakra-breadcrumb__list{
  padding-left: 0px !important;
}

.body-table{
  overflow: auto !important;
}

td{
  white-space: nowrap;
}

.top-bar-mobile{
  display: none !important;
}

.row-menu{
  /*box-shadow: 0px 0px 5px 1px #ebebeb56;*/
  box-shadow: 0px 0px 5px 1px #9595951c;
  border-radius: 10px;
}

.container-menu-voice{
  padding: 5px 10px 5px 10px;
  white-space: nowrap;
}

.badge-license{
  padding-left: 30px;
}

.copyright{
  font-size: 12px;
  position: fixed;
  width: 100%;
  bottom: 10px;
  text-align: center;
}

.copyright-mobile{
  width: 100%;
  text-align: center;
  font-size: 12px;
  padding: 10px;
}

.movement{
  border: 1px solid var(--border-color);
  border-radius: 10px;
  padding-bottom: 10px;
}

.line-document{
  border: 1px solid var(--border-color);
  border-radius: 10px;
}

.line-content{
  padding: 10px;
}

.circle{
  width: 10px;
  height: 10px;
  border-radius: 10000000000px;
}

.no-ping{
  background-color: #757575 !important;
}

.ok-ping{
  background-color: rgb(0, 216, 0) !important;
}

.warning-ping{
  background: orange;
}

.dangerous-ping{
  background-color: red;
}

.no-privacy-data{
  color: red !important;
  font-weight: bold;
}

.parent-chart{
  width: 100%;
  border: 1px solid var(--border-color);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}

.chart-percentage{
  width: 100%;
  display: flex;
  justify-content: center;
}

.chart-bars{
  width: 100%;
}

.badge-chart{
  font-weight: bold !important;
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
  display: initial !important;
  width: 100%;
  padding: 10px;
  font-size: 16px !important;
}

.badge-chart.small{
  width: 150px !important;
  text-align: center;
  border-radius: 0px !important;
}

.parent-chart.small{
  width: 50% !important;
}

@media screen and (max-width: 1300px) {
  .parent-chart.small{
    width: 100% !important;
  }
  .chart-percentage{
    padding: 10px !important;
  }
  .sidebar{
    display: none !important;
  }
  .top-bar-mobile{
    display: flex !important;
    height: 60px;
    background: rgb(255, 255, 255);
    box-shadow: 0px 5px 100px 0.1px #00000017;
    position: sticky !important;
    top: 0  !important;
    z-index: 999999;
  }
  .menu-drawer{
    padding: 0px !important;
  }
  .content .body{
    padding: 15px !important;
  }
  .copyright{
    display: none !important;
  }
  .stat-card{
    width: 100% !important;
  }
  .pagination{
    flex-wrap: wrap;
  }
}